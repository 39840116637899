import { nanoid } from "nanoid"

export const updateCountsArray = (array, name) => {
  let index = array.findIndex(item => item.name === name)
  if(index === -1) {
    array.push({ name: name, count: 1 })
  } else {
    array[index].count++
  }
}

export const sortArrayOfObjectsBy = (array, key) => {
  array.sort((a, b) => {
    let comparison = 0

    if (a[key] < b[key]) {
      comparison = 1
    } else if (a[key] > b[key]) {
      comparison = -1
    }
    return comparison
  })

  return array
}

export const isNumber = (value) => {
  return typeof value === "number" && isFinite(value)
}

export const objectsEqual = (o1, o2) => {
  const entries1 = Object.entries(o1)
  const entries2 = Object.entries(o2)

  if (entries1.length !== entries2.length) {
    return false
  }

  for (let i = 0; i < entries1.length; ++i) {
    // Keys
    if (entries1[i][0] !== entries2[i][0]) {
      return false
    }
    // Values
    if (entries1[i][1] !== entries2[i][1]) {
      return false
    }
  }

  return true
}


export const getAllTerms = (learningUnits) => {
  return [...new Set(learningUnits.reduce((a, v) => a.concat(v.main_terms) ,[]))].filter(t => t !== "").sort()
}

export const getSelectOptions = (terms) => {
  return terms.map(term => { return { value: term.drupal_id, label: term.name } })
}

export const getAgeRange = (nodes) => {
  return {
    min: nodes.reduce((agg, node) => { return Math.min(node.minimum_age, agg) }, 99),
    max: nodes.reduce((agg, node) => { return Math.max(node.maximum_age, agg) }, 0)
  }
}

export const getTeachingTimeLSRange = (nodes) => {
  return {
    min: 0,
    max: nodes.reduce((agg, node) => { return Math.max(node.teaching_time_minutes, agg) }, 0)
  }
}

export const getTeachingTimeLURange = (nodes) => {
  return {
    min: 0,
    max: nodes.reduce((agg, node) => { return Math.max((node.teaching_time_minutes || 0) * (node.duration_repeat || 0), agg) }, 0)
  }
}


export const getNewCustomLsId = (customLearningScenarios) => {
  const existingIds = customLearningScenarios.map(ls => ls.id)

  let i = 1
  while(existingIds.indexOf(i) !== -1) {
    i++;
  }

  return i
}



export const getCustomLSDataFromExistingLS = (id, lsToClone, allLearningUnits, t) => {

  // get data from the lu's via their reference from ls
  const luData = lsToClone.relationships.learning_units.filter(lu => lu.relationships.learning_unit_reference !== null).map(ref => {
    const full = allLearningUnits.find(full => full.id === ref?.relationships?.learning_unit_reference.id)

    return {
      id: nanoid(),
      title: full.title,
      description: full.short_abstract,
      phase: full.relationships.phase.drupal_id,
      format: full.relationships.format.map(term => term.drupal_id),
      steam_subjects: full.relationships.steam_subjects.map(term => term.drupal_id),
      partners: full.relationships.partners.map(term => term.drupal_id),
      location: full.relationships.location.map(term => term.drupal_id),
      engagement: full.relationships.engagement.map(term => term.drupal_id),
    }
  })

  return {
    id: id,
    baseId: nanoid(),
    buildMode: "customise existing",
    title: `${lsToClone.title} (${t("BUILD-COPY")})`,
    description: lsToClone.short_abstract,
    lu: luData
  }
}

export const getEmptyCustomLU = () => {
  return {
    id: nanoid(),
    title: "",
    description: "",
    phase: null,
    format: [],
    steam_subjects: [],
    partners: [],
    location: [],
    engagement: [],
  }
}

export const hashCode = function(s){
  return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
}

export const getHashLS = function(ls){
  //only use content fields to create hash
  let slim = {
    title: ls?.title,
    description: ls?.description,
    lu: ls?.lu
  }
  return hashCode(JSON.stringify(slim))
}
