import React from "react"

import { Link } from "gatsby-plugin-react-i18next"


const Breadcrumb = ({ items }) => (
  <div className="breadcrumb">
    { items.map(item => <Link key={item.label} to={item.link}>
        { item.label }
    </Link>) }
  </div>
)

export default Breadcrumb
