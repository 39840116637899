import React from "react"

import { printserviceUrl } from "../settings";

const encode = require('base64-arraybuffer').encode;

const Modal = ({ content, close }) => {
  const saveAsPdf = () => {
    if(printserviceUrl) {
      window.open(`${printserviceUrl}/generate?data=${encode(content)}`, '_blank');
    } else {
      window.print(); return false;
    }
  }

  return <div id="modal">
    <div id="modal-backdrop" onClick={ ()=> { close() } } onKeyDown={ ()=> { close() } } role="none" />
    <div id="modal-pane">
      <div className="flow">
        <div id="modal-content">
          <iframe title="attachment" src={`data:text/html;base64,${encode(content)}`}/>
        </div>
        <div className="align-end">
          <button className="button button-small" onClick={ ()=> { saveAsPdf() } }>↗ save as pdf</button>
          <button className="button button-small" onClick={ ()=> { close() } }>× close</button>
        </div>
      </div>
    </div>
  </div>

}

export default Modal
