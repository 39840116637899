import React, { useState } from "react"

import { isNumber } from "../util/data"


const TabSections = ({ tabs, activeDefault }) => {
  const activeInitial = (isNumber(activeDefault) && activeDefault <= tabs.length) ? activeDefault : 0

  const [ active, setActive ] = useState(tabs[activeInitial].title)

  return (
    <div className="tab-sections">
      <div className="tab-sections-header">
        { tabs.map(tab => <button key={ tab.title } className={ `${tab.title === active ? "active" : "inactive"}` } onClick={ () => { setActive(tab.title) } } >
          { tab.title }
        </button>) }
      </div>
      <div className="tab-sections-body">
        { tabs.map(tab => <div key={ tab.title } className={ `${tab.title === active ? "active" : "inactive"}` }>
          { tab.body }
        </div>) }
      </div>
    </div>
  )
}

export default TabSections
