import React, { useState, useContext } from "react"

import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

import { getParsedHtml, makeAgeRangeString } from "../util/format"

import { MyContext } from "../context/my-context"
import { upsert, remove } from "../context/records-reducer"

import Seo from "../parts/seo"
import Breadcrumb from "../parts/breadcrumb"
import Row from "../parts/row"
import Layout from "../parts/layout"
import List from "../parts/list"
import TabSections from "../parts/tabSections"
import LearningUnitStep from "../parts/learningUnitStep"
import HeadingUnderline from "../parts/headingUnderline"
import Modal from "../parts/modal"

import Print from "../images/inline/icon-print.svg"
import Favourite from "../images/inline/icon-favourite.svg"
import Share from "../images/inline/icon-share.svg"
import NotFavourite from "../images/inline/icon-not-favourite.svg"

const ReactDOMServer = require('react-dom/server');

const Buffer = require('buffer').Buffer;


const LearningUnit = ({ data }) => {
  const { t } = useI18next()

  const {
    id, drupal_id, title, short_abstract,
    minimum_age, maximum_age,
    preparation,
    main_concepts, specific_messages, specific_goals,
    duration_repeat, preparation_time_minutes, teaching_time_minutes,
    number: lu_number,
  } = data.nodeLearningUnit
  //console.log("d", data.allNodeLearningUnit)
  const phase = data.nodeLearningUnit.relationships.phase

  const steam_subjects = data.nodeLearningUnit.relationships.steam_subjects.map(subject => subject.name)
  const roles = data.nodeLearningUnit.relationships.partners.map(partner => partner.name)
  const locations = data.nodeLearningUnit.relationships.location.map(location => location.name)
  const formats = data.nodeLearningUnit.relationships.format.map(format => format.name)
  const engagements = data.nodeLearningUnit.relationships.engagement.map(engagement => engagement.name)

  const management_skills = data.nodeLearningUnit.relationships.management_skills.map(skill => skill.name)
  const soft_skills = data.nodeLearningUnit.relationships.soft_skills.map(skill => skill.name)
  const stem_skills = data.nodeLearningUnit.relationships.stem_skills.map(skill => skill.name)

  const { web_links, downloadable_resources, tangible_resources, human_resources } = data.nodeLearningUnit.relationships
  const resources = {
    "LU-WEB_LINKS": web_links,
    "LU-DOWNLOADABLE_RESOURCES": downloadable_resources,
    "LU-TANGIBLE_RESOURCES": tangible_resources,
    "LU-HUMAN_RESOURCES": human_resources
  }

  const course_of_activity = data.nodeLearningUnit.relationships.course_of_activity


  // nav
  const lu_nav = {}
  lu_nav.parent = data.nodeLearningUnit.relationships.paragraph__learning_unit?.[0].relationships.node__learning_scenario[0] || null

  if(lu_nav.parent) {
    //const learning_unit_siblings = lu_nav.parent.relationships.learning_units.map(lu => lu.relationships.learning_unit_reference)
    lu_nav.siblings = lu_nav.parent.relationships.learning_units.map(lu => lu.relationships.learning_unit_reference).filter(lu => lu !== null)
    lu_nav.current_index = lu_nav.siblings.findIndex(lu => lu.id === id)
    lu_nav.prev = lu_nav.current_index === 0 ? null : lu_nav.siblings[lu_nav.current_index-1]
    lu_nav.next = lu_nav.current_index+1 === lu_nav.siblings.length ? null : lu_nav.siblings[lu_nav.current_index+1]
  }

  const breadcrumbItems = [
    {
      label: t("NAV-HOME"),
      link: "/",
    },
    {
      label: t("NAV-MATERIALS"),
      link: "/open-schooling-materials",
    }
  ]

  if(lu_nav.parent) {
    breadcrumbItems.push({
      label: lu_nav.parent?.title,
      link: lu_nav.parent?.path.alias,
    })
  }

  const { state, dispatch } = useContext(MyContext)
  const favourite = state?.favourites.find((record) => record.id === drupal_id )

  const [ modalContent, setModalContent ] = useState(null)

  const openModal = (content) => {

    const transformedContent = ReactDOMServer.renderToString(getParsedHtml(content))
    const page = `<!DOCTYPE html><html><head><meta charset="utf8"><link rel="stylesheet" href="${window.location.origin}/attachment-styles.min.css" /></head>${transformedContent}</html>`
    const b = Buffer.from(page, 'utf8')
    setModalContent(b)
    document.body.style.overflow = "hidden"
  }

  const closeModal = () => {
    setModalContent(null)
    document.body.style.overflow = "unset"
  }

  return (
    <Layout links={data.allNodeLearningUnit?.nodes} currentNavItem={"open-schooling-materials"}>
      <Seo title={ title } />

      <Row classes={["no-margins no-print"]} >
        <Breadcrumb items={ breadcrumbItems } />
      </Row>

      { lu_nav.siblings && <Row classes={["no-margins no-print"]} >
        <ul id="lu-nav">
        { lu_nav.siblings.map(lu => {
          const classes = [ "lu" ]
          if(lu.relationships.phase) classes.push(`phase-${lu.relationships.phase.drupal_id}`)
          if(lu.number === lu_number) classes.push("current")

          return <li key={ lu.id } className={ classes.join(" ") }>
            <Link to={ lu.path.alias } language={ lu.path.langcode }>{ lu.number }</Link>
          </li>
        }) }
        </ul>
      </Row> }

      <div id="page-header" className={`pane lu-card ${phase ? `phase-${phase.drupal_id}` : "phase-none"}`}>
        <div className="pad-m">
          <div className="lu-header">
            <div>
              { lu_nav.prev && <Link className="lu-prev no-print" to={ lu_nav.prev.path.alias } title="previous" /> }
              { lu_nav.parent && <div className="lu-number">{ lu_number ? lu_number : lu_nav.current_index+1 }</div> }
              { lu_nav.next && <Link className="lu-next no-print" to={ lu_nav.next.path.alias } title="next" /> }
              { phase && <span className="lu-phase">{ phase.name }</span> }
            </div>
            <div>
              <div className="lu-time">
                { t("LU-PREPARATION_TIME") }: { t("MIN", { count: preparation_time_minutes }) }<br />
                { t("LU-TEACHING_TIME") }: { duration_repeat !== 1 ? `${duration_repeat} × ` : "" }{ t("MIN", { count: teaching_time_minutes }) }
              </div>

            </div>
          </div>

          <HeadingUnderline title={ title } classes={["center"]} />
          <div className="text-with-sidebar">
            <div>
              <p>{ short_abstract }</p>
            </div>
            <div className="flex-columns">
              <List title={ t("SEARCH-FORMAT") } titleWeight="h4" type="labels" items={formats} />
              <List title={ t("SEARCH-AGE") } titleWeight="h4" type="labels" items={[makeAgeRangeString(minimum_age, maximum_age)]} />
            </div>
          </div>

        </div>
        <div className="pad-m lu-card-footer flex-columns narrower-columns">
          <div className="what">
            <h3>{ t("SEARCH-WHAT") }</h3>
            <p className="small">{ t("WHAT-SUBTITLE") }</p>
            <List type="lines" items={steam_subjects} />
          </div>
          <div className="who">
            <h3>{ t("SEARCH-WHO") }</h3>
            <p className="small">{ t("WHO-SUBTITLE") }</p>
            <List type="lines" items={roles} />
          </div>
          <div className="where">
            <h3>{ t("SEARCH-WHERE") }</h3>
            <p className="small">{ t("WHERE-SUBTITLE") }</p>
            <List type="lines" items={locations} />
          </div>
          <div className="with">
            <h3>{ t("SEARCH-WITH") }</h3>
            <p className="small">{ t("WITH-SUBTITLE") }</p>
            <List type="lines" items={engagements} />
          </div>
        </div>
      </div>

      <Row classes={["no-print"]}>
        <h2 className="h3 center">{ t("ACTION") }</h2>
        <div className="ctas center">
          <button className="button" onClick={ () => { window.print(); return false; }}>
              <Print /> { t("BUTTONS-PRINT") }
          </button>

          { favourite ?
            <button className={`button ${favourite ? "active" : ""}`} onClick={ ()=> { remove(dispatch.favourites, drupal_id) } }>
              <Favourite /> { t("FAVOURITES-REMOVE") }
            </button>
            :
            <button className={`button ${favourite ? "active" : ""}`} onClick={ ()=> { upsert(dispatch.favourites, {id: drupal_id, type:"lu"}) } }>
              <NotFavourite /> { t("FAVOURITES-ADD") }
            </button>
          }

          <button className="button">
            <Share /> { t("BUTTONS-SHARE") }
          </button>
        </div>
      </Row>


      <Row>
        <TabSections tabs={[
          {
            title: t("LU-GETTING_STARTED"),
            body: <div className="flex-columns">

              <section id="resources" className="pane pad-m min-20 flow">
                <h2>{ t("LU-RESOURCES") }</h2>
                { Object.entries(resources).filter(resource => resource[1].length > 0).map((resource, i) => {
                  return <div className="flow" key={i}>
                    <h3>{ t(resource[0]) }</h3>
                    { resource[1].map(resourceItem => {
                      switch (resourceItem.internal.type) {
                        case "paragraph__resource_link":
                          let className = "icon"
                          if((resourceItem?.link?.url || "").includes("youtube") || (resourceItem?.link?.url || "").includes(".mp4")) className += " icon-video"

                          return <div key={resourceItem.id }>
                            <a className={className} href={ resourceItem.link?.url } target="_blank" rel="noopener noreferrer">
                              { resourceItem.link?.title }
                            </a>
                          </div>;

                        case "paragraph__resource_text":
                          return <div key={resourceItem.id }>
                            { getParsedHtml(resourceItem.text) }
                          </div>;

                        case "paragraph__resource_download_file_":
                          return <div key={resourceItem.id }>
                            <a href={ `https://openschoolingnavigator.eu${resourceItem.relationships?.upload?.uri?.url}` } target="_blank" rel="noopener noreferrer">{ resourceItem.title }</a>
                          </div>;

                        case "paragraph__resource_download_text_":
                          return <div key={resourceItem.id }>
                            <button className="button button-small button-down" onClick={ () => { openModal(/*getParsedHtml(*/resourceItem.text/*)*/) } }>{ resourceItem.title ? resourceItem.title : "open" }</button>
                          </div>;

                        default:
                          return <div key={resourceItem.id }>
                            type unknown...
                          </div>;
                      }

                    }) }
                  </div>
                }) }

              </section>

              { preparation && <section id="preparation" className="pane pad-m min-20">
                <h2>{ t("LU-PREPARATION") }</h2>
                { getParsedHtml(preparation.processed) }
              </section> }


            </div>
          },
          {
            title: t("LU-WHAT_STUDENTS_LEARN"),
            body: <div className="flex-columns">
              <section className="pane pad-m">
                <h2>{ t("LU-GOALS_MESSAGES_CONCEPTS") }</h2>
                <div className=" flex-columns">
                  <List title={ t("LU-SPECIFIC_GOALS") } titleWeight="h4" type="lines" items={specific_goals} />
                  <List title={ t("LU-SPECIFIC_MESSAGES") } titleWeight="h4" type="lines" items={specific_messages} />
                  <List title={ t("LU-MAIN_TERMS") } titleWeight="h4" type="lines" items={main_concepts} />
                </div>
              </section>
              <section className="pane pad-m">
                <h2>{ t("LU-PRACTICES_SKILLS") }</h2>
                <div className="flex-columns">
                  <List title={ t("LU-STEM_PRACTICES") } titleWeight="h4" type="lines" items={stem_skills} />
                  <List title={ t("LU-SOFT_SKILLS") } titleWeight="h4" type="lines" items={soft_skills} />
                  <List title={ t("LU-MANAGEMENT_SKILLS") } titleWeight="h4" type="lines" items={management_skills} />
                </div>
              </section>
            </div>
          },
          {
            title: t("LU-COURSE_OF_ACTIVITY"),
            body: <section id="course-of-activity" className="flow">
            <h2>{ t("LU-COURSE_OF_ACTIVITY") }</h2>
            { course_of_activity.map((step, i) => <LearningUnitStep key={ i } step={ step } number={ i+1 } /> ) }
          </section>
          },
        ]} />
      </Row>

      { modalContent && <Modal content={ modalContent } close={ closeModal } /> }

    </Layout>
  )
}

LearningUnit.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`

  fragment resource_text on paragraph__resource_text {
    internal {
      type
    }
    id
    text
  }

  fragment resource_link on paragraph__resource_link {
    internal {
      type
    }
    id
    link {
      title
      url
    }
  }

  fragment resource_download_text on paragraph__resource_download_text_ {
    internal {
      type
    }
    id
    title
    text
  }

  fragment resource_download_file on paragraph__resource_download_file_ {
    internal {
      type
    }
    id
    title
    upload {
      display
    }
    relationships {
      upload {
        uri {
          url
        }
      }
    }
  }

  query($language: String!, $gatsbyNodeId: String!, $drupalNodeId: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodeLearningUnit( filter: {drupal_id: {eq: $drupalNodeId}}) {
      nodes {
        path {
          alias
          langcode
        }
      }
    }
    nodeLearningUnit(id: {eq: $gatsbyNodeId}) {
      id
      drupal_id
      title
      number
      short_abstract
      teaser
      minimum_age
      maximum_age
      main_concepts
      specific_messages
      specific_goals
      duration_repeat
      teaching_time_minutes
      preparation_time_minutes
      preparation {
        processed
      }
      sources_and_resources {
        processed
      }
      relationships {
        web_links {
          ... on paragraph__resource_text { ...resource_text }
          ... on paragraph__resource_link { ...resource_link }
          ... on paragraph__resource_download_text_ { ...resource_download_text }
          ... on paragraph__resource_download_file_ { ...resource_download_file }
        }

        downloadable_resources {
          ... on paragraph__resource_text { ...resource_text }
          ... on paragraph__resource_link { ...resource_link }
          ... on paragraph__resource_download_text_ { ...resource_download_text }
          ... on paragraph__resource_download_file_ { ...resource_download_file }
        }

        tangible_resources {
          ... on paragraph__resource_text { ...resource_text }
          ... on paragraph__resource_link { ...resource_link }
          ... on paragraph__resource_download_text_ { ...resource_download_text }
          ... on paragraph__resource_download_file_ { ...resource_download_file }
        }

        human_resources {
          ... on paragraph__resource_text { ...resource_text }
          ... on paragraph__resource_link { ...resource_link }
          ... on paragraph__resource_download_text_ { ...resource_download_text }
          ... on paragraph__resource_download_file_ { ...resource_download_file }
        }

        paragraph__learning_unit {
          relationships {
            node__learning_scenario {
              title
              path {
                alias
              }
              relationships {
                learning_units {
                  relationships {
                    learning_unit_reference {
                      id
                      number
                      title
                      path {
                        alias
                      }
                      relationships {
                        phase {
                          name
                          drupal_id
                        }
                      }
                    }
                  }
                }
              }

            }
          }
        }
        course_of_activity {
          id
          title
          description {
            processed
          }
          relationships {
            more_infos {
              more_info_button
              more_info_text {
                processed
              }
            }
          }
        }
        format {
          name
        }
        location {
          name
        }
        management_skills {
          name
        }
        partners {
          name
        }
        engagement {
          name
        }
        phase {
          name
          drupal_id
        }
        soft_skills {
          name
        }
        steam_subjects {
          name
        }
        stem_skills {
          name
        }
      }
    }
  }
`

export default LearningUnit
