import ReactHtmlParser, { convertNodeToElement } from "react-html-parser"


export const makeAgeRangeString = (min, max) => {
  let string = min

  string += max === 18 ? "+" : `-${max}`

  return string
}

export const makeTimeString = (minutesTotal, t) => {
  const hours = Math.floor(minutesTotal / 60)
  const minutes = minutesTotal - hours * 60

  let string = t("HRS", { count: hours })

  if(minutes !== 0) {
    string += ", " + t("MIN", { count: minutes })
  }

  return string
}

export const formatDateTime = (datetime, language) => {
  return new Intl.DateTimeFormat(language, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }).format(new Date(datetime))
}


export const preprocessText = (string) => {
  // Replace the \n with <br>
  string = string.replace(/(?:\r\n|\r|\n)/g, "<br>")

  return string
}


export const preprocessHtml = (string) => {
  if(!string) return ""

  // remove lang attrs
  string = string.replace(/xml:lang/g, "")

  // remove empty spans
  while (string.indexOf('<span>') !== -1) {
    string = string.replace('<span>', '').replace('</span>', '')
  }

  // remove space within p so it takes less space (margin only)
  //string = string.replace(/<p>\s/g, '<p>')

  // complete img urls
  // while (string.indexOf('src="/sites/') !== -1) {
  //   string = string.replace('src="/sites/', 'src="https://openschoolingnavigator.eu/sites/')
  // }

  // wrap tables
  while (string.indexOf('<table>') !== -1) {
    string = string.replace('<table>', '<div class="table-wrapper"><table class="wrapped">').replace('</table>', '</table></div>')
  }

  return string
}


export const getParsedHtml = (html) => {
  html = preprocessHtml(html)

  function transform(node, index) {
    if (node.type === "tag" && node.name === "p") {
      // remove empty paragraphs
      if(node.children.length === 1 && node.children[0].data === `${String.fromCharCode(160)}`) {
        return null
      }
    }
  
    // replace relative img src to full urls
    if (node.type === "tag" && node.name === "img") {
      if(node.attribs.src.startsWith("/sites")) {
        node.attribs.src = node.attribs.src.replace("/sites", "https://openschoolingnavigator.eu/sites")
      }
  
      return convertNodeToElement(node, index, transform)
    }
  
    if (node.type === "tag" && node.name === "a") {
      // handle external links
      if(node.attribs.href?.includes("http") && !node.attribs.href?.includes("openschoolingnavigator.eu")) {
        node.attribs.target = "_blank"
        node.attribs.rel = "noreferrer noopener"
        node.attribs.class = "external"
      }
    }

    // remove styles from divs, maybe too harsh...
    if (node.type === "tag" && node.name === "div") {
      //console.log(node.attribs)
      node.attribs.style = ""
    }
  }

  return ReactHtmlParser(html, { transform })
}
