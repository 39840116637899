import React from "react"

import { getParsedHtml } from "../util/format"


const LearningUnitStep = ({ step, number }) => {
  const { title, duration_in_minutes, more_infos } = step
  const description = step.description ? step.description.processed : ""

  return <div className={`lu-step pane pad-s`} role="none">
    <h3>{ title ? title : `step ${number}` } { duration_in_minutes && <span>({ duration_in_minutes } min.)</span> }</h3>
    { description && <div className={`lu-step-content`}>
      { getParsedHtml(description) }
    </div> }
    { more_infos && more_infos.length > 0 && more_infos.map((more_info, i) => {
      return <details key={ i } className={`lu-step-more`}>
      <summary>more info</summary>
      <em>{ getParsedHtml(more_info.more_info_text_processed) }</em>
    </details>
    }) }
  </div>
}

export default LearningUnitStep
